.select_tracking {
  border: none !important;
}

.hideScrollBar::-webkit-scrollbar {
  display: none !important;
}

.employee_list_container_wrapper {
  padding-left: 20px;
  padding-right: 20px;
  gap: 20px;
}

.employee_list_container {
  cursor: pointer;
  border-radius: 16px;
  transition: transform 0.3s ease-in;
  // width: 384px;
  // min-width: 384px;
  // max-width: 384px;
  width: 100%;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
}

body:not([class*="rtl"]) .employee_list_container {
  transform-origin: left;
}

body[class*="rtl"] .employee_list_container {
  transform-origin: right;
}

.employee_list_container_active {
  transform: scaleX(1.05);
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
}

.employee_list_container:hover {
  transform: scaleX(1.05);
}

.employee_name {
  font-size: 16px;
  text-transform: capitalize;
}

@media only screen and (max-width: 600px) {
  .employee_list_container_wrapper {
    justify-content: center;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .employee_list_container {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    transform-origin: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 10px;
  }

  .employee_list_container_active {
    transform: scale(1.05);
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
  }

  .employee_list_container:hover {
    transform: scale(1.05);
  }

  .employee_name {
    text-align: center;
  }
}

.employee_model_map {
  width: 128px;
  height: 155px;
  transform: translate(-64px, -175px);
  position: relative;
  text-align: center;
}

.employee_model_leaflet_map {
  width: 128px;
  // height: 155px;
  position: relative;
  text-align: center;
}

.emp_map_model {
  width: 110px;
  height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.employee_model_map_path {
  position: absolute;
  bottom: -9px;
  left: 50%;
  width: 1em;
  height: 1em;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  // border-bottom: 20px solid blue; /* Change the color as needed */
  transform: translate(-50%, 50%) rotate(180deg);
}

// .employee_model_map::after {
//   content: "";
//   position: absolute;
//   bottom: 2px;
//   left: 50%;
//   width: 20px;
//   height: 20px;
//   background-color: white;
//   border-radius: 5px;
//   transform: translate(-50%, 50%) rotate(45deg);
// }

.employee_list_image {
  width: 90px;
  height: 90px;
  // z-index: 1;
  position: relative;
  border-radius: 50%;
}

.employee_list_image_background {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 3px;
  filter: blur(2px);
  border-radius: 50%;
}
.tracking_btn {
  text-transform: capitalize;
  border-radius: 16px;
  // width: 112px !important;
  height: 56px !important;
}
@media screen and (max-width: 1199px) {
  .employee_list_image_background {
    width: 63px;
    height: 63px;
  }
  .employee_list_image {
    width: 63px;
    height: 63px;
  }
  .employee_name {
    font-size: 13px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .employee_list_container {
    gap: 13px;
  }

  .employee_model_map {
    width: 118px;
    height: 140px;
    transform: translate(-59px, -160px);
  }
  .tracking_btn {
    text-transform: capitalize;
    border-radius: 16px;
    width: 92px !important;
    height: 46px !important;
  }
}

// maps
.employees_map {
  width: 100% !important;
  height: 100% !important;
}

#gmaps-markers {
  width: 100% !important;
  height: 100% !important;
}

//

.wraper_emp {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: auto;
  height: 73vh;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}

.emp_items {
  min-width: 360px;
  height: 90%;
  border-radius: 10px;
  transition: scale 0.2s ease-in;
  overflow: hidden;
}

.emp_items:hover {
  scale: 1.02;
}

.map_employee_list_image {
  width: 72px;
  height: 72px;
  // z-index: 1;
  position: absolute;
  transform: translate(0, -50%);
  border-radius: 50%;
  top: 0px;
  left: 0px;
}

.map_employee_list_image_background {
  width: 71px;
  height: 71px;
  transform: translate(0, -50%);
  position: absolute;
  top: 3px;
  filter: blur(2px);
  border-radius: 50%;
  background-color: black;
}

.employees_swiper {
  transition: scale 0.2s ease-in;
  transition: filter 0.2s ease-in;
}

.employees_swiper_active {
  transform: scale(1.2);
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
}

.selectInputSearch {
  width: 100% !important;
}

body:not([class*="rtl"]) .selectInputSearch {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

body[class*="rtl"] .selectInputSearch {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.searchEmployeeInput {
  // max-width: 544px !important;
  // width: 150px !important;
  border-radius: 0px !important;
  z-index: 3;
  height: 53px;
}
.map_card_title {
  text-wrap: nowrap;
  transition: all 0.3s ease-in;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
}

body:not([class*="rtl"]) .searchIcon {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

body[class*="rtl"] .searchIcon {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
@media screen and (max-width: 768px) {
  body:not([class*="rtl"]) .selectInputSearch {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  body[class*="rtl"] .selectInputSearch {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  body:not([class*="rtl"]) .searchEmployeeInput {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  body[class*="rtl"] .searchEmployeeInput {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
}
