.selectInputSearch {
  height: 53px !important;
  width: 100% !important;
}
body:not([class*="rtl"]) .selectInputSearch {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

body[class*="rtl"] .selectInputSearch {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.searchEmployeeInput {
  border-radius: 0px !important;
  z-index: 3;
  height: 53px;
}
body:not([class*="rtl"]) .searchIcon {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

body[class*="rtl"] .searchIcon {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

@media screen and (max-width: 768px) {
  body:not([class*="rtl"]) .selectInputSearch {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  body[class*="rtl"] .selectInputSearch {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  body:not([class*="rtl"]) .searchEmployeeInput {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  body[class*="rtl"] .searchEmployeeInput {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
}

// employees list
.hideScrollBar::-webkit-scrollbar {
  display: none !important;
}

.employee_list_container_wrapper {
  padding-left: 20px;
  padding-right: 5px;
  gap: 20px;
}

.employee_list_container {
  cursor: pointer;
  border-radius: 16px;
  transition: transform 0.3s ease-in;
  transform-origin: left;
  width: 100%;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
}

.employee_list_image_background {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 3px;
  filter: blur(2px);
  border-radius: 50%;
}

.employee_list_image {
  width: 90px;
  height: 90px;
  // z-index: 1;
  position: relative;
  border-radius: 50%;
}

.employee_name {
  font-size: 16px;
  text-transform: capitalize;
}

.checkbox_Container {
  position: absolute !important;
  top: 10px;
  left: 1px;
  z-index: 1;
}
.checkbox_Container > input {
  transform: scale(1.3);
}

.checkbox_Employees_Container {
  margin-bottom: 10px;
}
.checkbox_Employees_Container > input {
  transform: scale(1.3);
  margin-left: 25px;
}

@media only screen and (max-width: 600px) {
  .employee_list_container_wrapper {
    justify-content: center;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .employee_list_container {
    width: 100%;
    min-width: 120px;
    transform-origin: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 10px;
  }

  .employee_name {
    text-align: center;
  }
}

@media screen and (max-width: 1199px) {
  .employee_list_image_background {
    width: 63px;
    height: 63px;
  }
  .employee_list_container {
    gap: 13px;
  }
  .employee_list_image {
    width: 63px;
    height: 63px;
  }

  .employee_name {
    font-size: 13px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
