.nav_container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.coordinates_list {
  position: absolute;
  top: 15%;
  right: 10px;
  width: 25%;
  min-width: 250px;
  height: 70%;
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  .coordinates_list {
    top: 17.5%;
    height: 65%;
  }
}
