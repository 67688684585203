.map_image {
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map_image_active {
  border-radius: 50%;
  position: relative;
  width: 100px;
  height: 100px;
  background-color: white;
  z-index: 10;
  transition: box-shadow 0.3s ease-in-out; /* Add a transition effect */
}

.map_image_active_punshIn {
  box-shadow: 0 0 0 7px #014421, 0 0 0 15px #014421b6, 0 0 0 25px #01442180;
}

.map_image_active_punchOut {
  box-shadow: 0 0 0 7px #d3af37, 0 0 0 15px #d3af37b2, 0 0 0 25px #d3af377f;
}

.map_image_active_notPunchIn {
  box-shadow: 0 0 0 7px #800000, 0 0 0 15px #800000b4, 0 0 0 25px #8000007e;
}

.map_image_active_punshIn:hover {
  box-shadow: 0 0 0 5px #014421, 0 0 0 10px #014421b6, 0 0 0 15px #01442180;
}

.map_image_active_punchOut:hover {
  box-shadow: 0 0 0 5px #d3af37, 0 0 0 10px #d3af37b2, 0 0 0 15px #d3af377f;
}

.map_image_active_notPunchIn:hover {
  box-shadow: 0 0 0 5px #800000, 0 0 0 10px #800000b4, 0 0 0 15px #8000007e;
}

.map_image_active::before,
.map_image_active::after {
  content: "";
  display: block;
  /* height: 60px; */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.map_image_active::before {
  background-color: red;
  /* z-index: -10; */
  animation: externalMove 1s infinite;
}

.map_image_active::after {
  background-color: red;
  /* z-index: -5; */
  animation: internalMove 1s infinite;
}

@keyframes externalMove {
  0% {
    width: 40%;
    padding-bottom: 40%;
  }
  25% {
    width: 45%;
    padding-bottom: 45%;
  }
  50% {
    width: 50%;
    padding-bottom: 50%;
  }
  75% {
    width: 55%;
    padding-bottom: 55%;
  }
  100% {
    width: 60%;
    padding-bottom: 60%;
  }
}
